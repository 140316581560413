import React from 'react'
import Lottie from "lottie-react";
import finallyLottieLoad from "./99297-loading-files.json";
import "./lottie.css"

const FinallyLottieLoad = () => {
  return (
    <div style={{display:"flex", alignItems:"center", flexDirection:"column" ,justifyContent:"center", width:"100%", height:"100%", clip:"rect( 0, 250px, 125px, 0 )",  filter:"blur(0px)", }}>
        <div className='lottie-ansver-loading' >
            <Lottie animationData={finallyLottieLoad} loop={true} />
        </div>
        Tekshirilmoqda
    </div>
  )
}

export default FinallyLottieLoad