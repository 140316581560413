import React, { memo } from "react";
import Timer from "../../components/Timer/timer";
import "./tests.css";
import "../../components/Timer/timer.css";
import Forma from "../Forma/Forma.jsx";

const Tests = () => {
  return (
    <>
      <Timer />
      <div className="tests">
        <Forma />
      </div>
    </>
  );
};

export default memo(Tests);
