import { createSlice } from "@reduxjs/toolkit";

export const testesReducer = createSlice({
  name: "testesReducer",
  initialState: {
    data:[],
    values:{ answers:[], test_id:null,},
    finallyData:{}
  },
  reducers: {
    setData:(state, {payload}) => {
      state.data = payload
    },
    setValues:(state, {payload}) => {
      state.values = payload
    },
    setCode:(state, {payload}) => {
    state.values.test_id = payload
    },
    setFinallyData:(state, {payload}) => {
      state.finallyData = payload
    }
  },
});

export const { setData, setValues, setCode, setFinallyData } = testesReducer.actions;

export default testesReducer.reducer;
