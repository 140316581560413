import { InputNumber,Button,message } from "antd";
import React, { useState } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setCode, setData } from "../../redux/testesReducer";
import "./login.css"
import { useNavigate } from "react-router-dom";
import { setUser } from "../../redux/authReducer";
import FinallyLottieLoadLogin from "../../components/Lottie/FinallyLottieLoad"
import { baseUrl } from "../../baseUrl";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [code, setCodeLocal] = useState(null)
  const [loaclLoading, setLocalLoading] = useState(false)


  const handleLogin = (e) => {
    e.preventDefault()
    setLocalLoading(true)

      axios
        .get(`${baseUrl}/questions-random/${code}`)
        .then((res) => {
          if (res.status === 200) {
            dispatch(setData(res.data.data));
            dispatch(setUser(true))
            dispatch(setCode(code.toString().slice(3)))
            navigate("/bottest/tests");
          }else{
            throw new Error(res.data.data)
          }
        }).catch(e => {
          navigate("/bottest");
          message.error(e.message)
        });
    setLocalLoading(false)

  }


  if (loaclLoading) {
    return <FinallyLottieLoadLogin />
  }

  return (
    <div className="login-wrap" >
      <div className="login-wrap__container">

          <div className="login-title" >
            <h2 style={{textAlign:"center", margin:"0"}}>Hurmatli nomzod </h2>
          </div>

          <div className="login-text" >
            <ul style={{listStyle:"none"}}>
              <li>⏳  Belgilangan vaqt: <span style={{ fontWeight:"bold"}}> 20 daqiqa</span></li>
              <li>❔  Jami savollar soni: <span style={{ fontWeight:"bold"}}> 20 ta</span></li>
              <li>🟢 Imkoniyat: <span style={{ fontWeight:"bold"}}>1 martta </span></li>
            </ul> 
          </div>
            <p className="login-p">Bemalol vaqt ajratib testni boshlashingizni tavsiya qilamiz 🙂</p>

          <form className="login-form" onSubmit={handleLogin}>
            <InputNumber className="login-input" min={0} onChange={(e) => setCodeLocal(e)} required  type="number" autoFocus={true} placeholder="Kod"/>
            <Button type="primary" htmlType="submit" className="login-submit__button" >Testni boshlash</Button>
          </form>


      </div>
    </div>
  );
};

export default Login;
