import { createSlice } from "@reduxjs/toolkit";

export const authReducer = createSlice({
  name: "authReducer",
  initialState: {
    user:false,
    isFinally:false,
  },
  reducers: {
    setUser:(state, {payload}) => {
      state.user = payload
    },
    setFinally:(state, {payload}) => {
        state.isFinally = payload
    },

  },
});

export const { setUser,setFinally } = authReducer.actions;

export default authReducer.reducer;
