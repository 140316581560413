import React, { memo } from "react";
import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { setFinally } from "../../redux/authReducer";
// import { setFinallyData } from "../../redux/testesReducer";

const Finally = () => {
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { finallyData } = useSelector((state) => state.testesReducer);


  // const handleLogOut = () => {
  //   dispatch(setFinally(false));
  //   dispatch(setFinallyData({}));
  //   navigate("/bottest/");
  // };

  return (
    <div className="login-wrap">
      <div className="login-wrap__container">

        <div className="login-title" >
            <h2 style={{textAlign:"center"}}>Test Natijasi</h2>
        </div>

        <div className="login-text" >
            <ul>
              <li> Jami savollar soni: <span style={{ fontWeight:"bold"}}>{finallyData.total_answer} ta </span> </li>
              <li> To'g'ri javoblar soni: <span style={{ fontWeight:"bold"}}> {finallyData.correct_answer} ta </span></li>
              <li> Noto'g'ri javoblar soni: <span style={{ fontWeight:"bold"}}> {finallyData.total_answer - finallyData.correct_answer} ta</span></li>
              <li> Natija : <span style={{ fontWeight:"bold"}}> {100*(finallyData.correct_answer / finallyData.total_answer)} % </span></li>
            </ul>
          </div>
          
          <p className="login-p">Sizning natijangiz ko'rib chiqilgach, siz bilan aloqaga chiqamiz</p>

        <form className="login-form" >
            {/* <Button type="primary" onClick={handleLogOut} className="login-submit__button" >Testni boshlash</Button> */}
          </form>

      </div>
    </div>
  );
};

export default memo(Finally);
