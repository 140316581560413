import { createSlice } from "@reduxjs/toolkit";

export const timeReducer = createSlice({
  name: "timeReducer",
  initialState: {
    time:0,
    width:0,
  },
  reducers: {
    setTime:(state, {payload}) =>{
      state.time = payload
      state.width = 100 * payload / 1200;
    }
  },
});

export const { setTime } = timeReducer.actions;

export default timeReducer.reducer;
