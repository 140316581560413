import { FieldTimeOutlined } from "@ant-design/icons";
import React, { memo, useEffect, useState } from "react";
import "./timer.css";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setTime } from "../../redux/timeReducer";
import axios from "axios"
import { setFinally, setUser } from "../../redux/authReducer";
import { useNavigate } from "react-router-dom";
import {message} from "antd"
import Finally from "../../pages/Finally/Finally";
import { setCode, setData, setFinallyData, setValues } from "../../redux/testesReducer";
import FinallyLottieLoadLogin from "../../components/Lottie/FinallyLottieLoad"
import { baseUrl } from "../../baseUrl";


const Timer = () => {
  const timeOut = 1200;
  const { time, width } = useSelector((state) => state.timeReducer);
  const { values } = useSelector((state) => state.testesReducer);
  const formatted = moment.utc(time * 1000).format("HH:mm:ss");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loaclLoading, setLocalLoading] = useState(false)


  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(setTime(time +1));
    }, 1000);

    return () => clearInterval(interval);
  }, [time]);


  if (loaclLoading) {
    return <FinallyLottieLoadLogin/>
  }

  if (time === timeOut) {
    try{
      setLocalLoading(true)
      axios.post(`${baseUrl}/questions-check`, {...values, time:formatted })
      .then((res) => {
        if (res.status === 200) {
            dispatch(setFinally(true))
            dispatch(setData([]));
            dispatch(setValues({answers:[], code:null}))
            dispatch(setUser(false))
            dispatch(setCode(null))
            dispatch(setTime(0));
            dispatch(setFinallyData(res.data))
            navigate("/finally");
        }else{
          throw new Error("Xatolik")
        }
      }).catch(e => {
        message.error(e.message)
      });
      setLocalLoading(false)

      return <Finally />
    }
    catch(err){
      message.error(err.message)
    }
  }


  return (
    <div className="timer">
      <div style={{width:"100%", height:"100%"}}>
        <div className="view-time">
          <div>
            <h3><FieldTimeOutlined />  Vaqt:</h3>
          </div>
          <div><h3>00:20:00 / {formatted}</h3></div>
        </div>
        <div className="progress">
          <div className="progress-done" style={{ width: `${width}%`, opacity: 1 }} > </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Timer);
