import React from "react";
import "./Loading.css";

const Loading = () => {
  return (
    <div className="loading-body">
      <main>
        <div className="preloader">
          <div className="preloader__square"></div>
          <div className="preloader__square"></div>
          <div className="preloader__square"></div>
          <div className="preloader__square"></div>
        </div>
        <div className="status">
          Yuklanmoqda<span className="status__dot">.</span>
          <span className="status__dot">.</span>
          <span className="status__dot">.</span>
        </div>
      </main>
    </div>
  );
};

export default Loading;
