import React from "react";
import Login from "./pages/Login/Login";
import Tests from "./pages/Tests/Tests";
import "./styles/global.css";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import Finally from "./pages/Finally/Finally";


function App() {
  const { user,isFinally } = useSelector((state) => state.authReducer);

  if (isFinally) {
    return (
      <Routes>
        <Route path="/bottest/finally" element={<Finally />} exact={true} />
        <Route path="*" element={<Finally to="/bottest/finally" replace />}/>
      </Routes>
    );
  } else if (user) {
      return (
        <>
          <Routes>
            <Route path="/bottest/tests" element={<Tests />} exact={true} />
            <Route path="*" element={<Tests to="/bottest/tests" replace />}/>
          </Routes>
        </>
      );
    } else {
        return (
          <Routes>
            <Route path="/bottest" element={<Login />} exact={true} />
            <Route path="*" element={<Login to="/bottest" replace />}/>
          </Routes>
        );
  }
}

export default App;

