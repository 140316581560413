import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCode, setData, setFinallyData, setValues } from "../../redux/testesReducer";
import axios from "axios";
import {message, Button} from 'antd'
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { setFinally, setUser } from "../../redux/authReducer";
import { setTime } from "../../redux/timeReducer";
import FinallyLottieLoadLogin from "../../components/Lottie/FinallyLottieLoad"
import { baseUrl } from "../../baseUrl";


const Forma = () => {
  const dispatch = useDispatch();
  const { data, values } = useSelector((state) => state.testesReducer);
  const { time } = useSelector((state) => state.timeReducer);
  const navigate = useNavigate();
  const [loaclLoading, setLocalLoading] = useState(false)
  const formatted = moment.utc(time*1000).format('HH:mm:ss');

  const onChangeInput = (newItem) => {
    const findItem = values.answers.find(item => item.question_id === newItem.question_id ? true : false)

    if (findItem) {
      const newValue = values && values.answers.map(item =>  item.question_id === newItem.question_id ? newItem : item )
      dispatch(setValues({...values, answers: newValue}))
    }else{
      dispatch(setValues({...values, answers:[...values.answers, {...newItem}] }))
    }
  };

  const getValue = (item, option) => {
    let is = false;
    values.answers.forEach(obj => {
      if (obj.question_id === item.id) {
        is = obj.option === option
      }
    });
    return is
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const headers = { 'Authorization': 'Bearer my-token', 'My-Custom-Header': 'foobar'};
    const bothChecked = document.querySelectorAll(`.check-div input[type='radio']:checked`).length === 20;
    
    if (bothChecked) {
      setLocalLoading(true)
      axios.post(`${baseUrl}/questions-check`, {...values, time: formatted }, { headers })
        .then((res) => {
          if (res.status === 200) {
            dispatch(setFinally(true))
            dispatch(setData([]));
            dispatch(setValues({answers:[], test_id:null}))
            dispatch(setUser(false))
            dispatch(setCode(null))
            dispatch(setTime(0));
            dispatch(setFinallyData(res.data))
            navigate("/bottest/finally");
          }else{
            throw new Error("Xatolik")
          }
        }).catch(e => {
          message.error(e.message)
        });
        setLocalLoading(true)
    }else{
      message.warning("Javoblar to'liq belgilanmagan")
    }

  }


  if (loaclLoading) {
    return <FinallyLottieLoadLogin />
  }



  return (
    <form onSubmit={onSubmit}>
      <div className="test-box">
        {data &&
          data.map((item, i) => {
            return (
              <div className="test-wrap" key={`key${item.id}`}>
                <div className="test-header">
                  <h2>{i+ 1} - Savol </h2>
                </div>
                <div className="question">{item.question}</div>
                <div className="varyants">
                  <div className="check-div">
                    <input className="check-input" name={`name${item.id}`} id={item.options.option_1} type="radio" checked={getValue(item, Object.keys(item.options)[0])} onChange={(e) => { onChangeInput({ answer: item.answer, option: Object.keys(item.options)[0], question_id: item.id, })}} />
                    <span className="variant">A)&nbsp; </span>
                    <label className="label" htmlFor="" onClick={(e) => { onChangeInput({ answer: item.answer, option: Object.keys(item.options)[0], question_id: item.id, })}}> {item.options[Object.keys(item.options)[0]]} </label>
                  </div>
                  <div className="check-div">
                    <input className="check-input" name={`name${item.id}`} id={item.options.option_2} type="radio" checked={getValue(item, Object.keys(item.options)[1])} onChange={(e) => { onChangeInput({ answer: item.answer, option: Object.keys(item.options)[1], question_id: item.id, })}} />
                    <span className="variant">B)&nbsp; </span>
                    <label className="label" htmlFor="" onClick={(e) => { onChangeInput({ answer: item.answer, option: Object.keys(item.options)[1], question_id: item.id, })}} > {item.options[Object.keys(item.options)[1]]}</label>
                  </div>
                  <div className="check-div">
                    <input className="check-input" name={`name${item.id}`} id={item.options.option_3} type="radio" checked={getValue(item, Object.keys(item.options)[2])} onChange={(e) => { onChangeInput({ answer: item.answer, option: Object.keys(item.options)[2], question_id: item.id, })}} />
                    <span className="variant">C)&nbsp; </span>
                    <label className="label" htmlFor="" onClick={(e) => { onChangeInput({ answer: item.answer, option: Object.keys(item.options)[2], question_id: item.id, })}} > {item.options[Object.keys(item.options)[2]]}</label>
                  </div>
                  <div className="check-div">
                    <input className="check-input" name={`name${item.id}`} id={item.options.option_4} type="radio" checked={getValue(item, Object.keys(item.options)[3])} onChange={(e) => { onChangeInput({ answer: item.answer, option: Object.keys(item.options)[3], question_id: item.id, })}} />
                    <span className="variant">D)&nbsp; </span>
                    <label className="label" htmlFor="" onClick={(e) => { onChangeInput({ answer: item.answer, option: Object.keys(item.options)[3], question_id: item.id, })}}> {item.options[Object.keys(item.options)[3]]} </label>
                  </div>
                </div>
              </div>
            );
          })}
        <div className="form-controller">
          <Button type="primary" htmlType="submit" > Testni Yakunlash </Button>
        </div>
      </div>

    </form>
  );
};

export default memo(Forma);
